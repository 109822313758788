<template>
    <div class="task-page-wrapper">
        <transition name="fade">
            <div v-if="task">
                <TaskPage :task="task" :instructions="instructions" @update="loadTask"
                          @formDirtyChange="$emit('formDirtyChange', $event)"/>
            </div>
        </transition>
        <div v-if="!task" class="task-page-wrapper__loading">
            <CSpinner color="primary"/>
        </div>
    </div>
</template>

<script>
import TaskPage from '@/components/TaskPages/TaskPage.vue';
import Tasks from "@/api/v2/endpoints/Tasks";
import {mapGetters} from "vuex";

const GENERAL_CONTRACTOR_INSTRUCTIONS = {
    'Contracts': [
        'Your sub has received the instructions to download, sign and send you back this contract.',
        'Then, upload the signed contract.',
    ],
    'Request documents': [
        'Approve or reject the provided document',
        'If a document is rejected, please provide an explanation to your partner',
    ],
    'Acknowledge': ['Your sub has received the notification to complete this task']
}

const SUBCONTRACTOR_INSTRUCTIONS = {
    'Contracts': [
        'Download this contract.',
        'Sign it or reach out back if you need some changes.',
        'Then you can send it back via email to the sender.',
    ],
    'Request documents': [
        'You can download and use the attached sample.',
        'Or you can upload your own document.'
    ],
    'Acknowledge': ['Please review and acknowledge the document below']
}

const TASK_DATA = [
    'id',
    'name',
    'note',
    'type',
    'task_id',
    'status',
    'is_main',
    'scope_id',
    'priority',
    'required_action',
    'internal_signer_id',
    'internal_assignee_id',
    'external_assignee_id',
    'external_signer_id',
    'complete_date',
    'start_date',
    'due_date',
    'is_overdue',
    'file_name'
];

export default {
    name: 'TaskPageWrapper',
    components: {TaskPage},
    data() {
        return {
            loading: false,
            taskId: null,
            task: null,
            userType: null,
        };
    },
    computed: {
        ...mapGetters(['user']),
        instructions() {
            return this.user.company.id === this.task.external_company.id
                ? SUBCONTRACTOR_INSTRUCTIONS[this.task.type]
                : GENERAL_CONTRACTOR_INSTRUCTIONS[this.task.type];
        },
    },
    created() {
        this.taskId = this.$route.params.task_id;
        this.loadTask();
    },
    methods: {
        loadTask() {
            Tasks
                .show(this.taskId, {
                    task_data: TASK_DATA.toString(),
                    with_external_company: 'id,name',
                    with_internal_company: 'id,name',
                    with_company_assigner_users: 'id,full_name',
                    with_company_signer_users: 'id,full_name',
                    with_task_approval: 'all',
                    with_project: 'name',
                    with_uploads: 'all',
                    with_latest_upload: 'all',
                    with_task_file_updates: 1
                })
                .then(response => this.task = response.data);
        },
    },
};
</script>

<style lang="scss">
.task-page-wrapper__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    padding: 40px;
    min-height: calc(100vh - 200px);
}
</style>
