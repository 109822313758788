<template>
    <div class="task-file-updates">
        <div class="heading">
            <CIcon name="cilDocument"/>
            Contracts Updates
        </div>
        <div class="list">
            <div v-for="update in taskFileUpdates" :key="update.id" class="item">
                <span @click="toggleDocumentPreviewer(update.id)">{{ taskFileName }}</span>
                -
                <strong>Updated:</strong>
                {{ formatDateTime(update.updated_at) }}
                <strong>By:</strong>
                {{ update.user.full_name }}
            </div>
        </div>
    </div>
    <DocumentPreviewer :visible="previewer.visible" :type-id="taskId" type="tasks" route="preview-file-update"
                       :query="previewer.params" @close="toggleDocumentPreviewer"/>
</template>

<script>

import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import dateFormater from "@/mixin/dateFormater";

export default {
    name: "TaskFileUpdates",
    components: {DocumentPreviewer},
    mixins: [dateFormater],
    props: {
        taskId: Number,
        taskFileName: String,
        taskFileUpdates: Array,
    },
    data() {
        return {
            previewer: {
                visible: false,
                params: null
            },
        }
    },
    methods: {
        toggleDocumentPreviewer(file_update_id = null) {
            this.previewer.visible = !this.previewer.visible;
            this.previewer.params = {file_update_id};
        },
    }
}
</script>

<style scoped lang="scss">
.task-file-updates {
    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .reason {
        background: none;
        border: none;
        outline: none;
        padding: 0 5px;
    }

    .heading {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgba(28, 38, 47, 1);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .item {
        color: rgba(28, 38, 47, 1);

        & span {
            color: rgba(0, 129, 194, 1);
            text-decoration: underline;
            cursor: pointer;
        }

        & strong {
            color: rgba(103, 122, 137, 1);
            font-weight: 700;
            font-size: 12px;
        }

        & svg {
            cursor: pointer;

            &:hover {

                & path {
                    fill: rgba(0, 129, 194, 1);
                }
            }
        }
    }
}
</style>
