<template>
    <div class="task-page-documents">
        <section v-if="isContract">
            <div v-if="isFullyExecuted" class="mb-3">
                <div class="task-page-documents__title">
                    <GreenCheckIcon/>
                    Signed Contract
                </div>
                <div class="task-page-documents__area task-page-documents__area_green">
                    <span class="task-page-documents__link"
                          @click="toggleDocumentPreview('tasks', task.id, 'preview-uploaded-file')">
                        {{ task.file_name }}
                    </span>
                </div>
                <div class="task-page-documents__buttons" v-if="isTaskGeneralContractor">
                    <LoadingButton :loading="loading" color="danger" variant="outline"
                                   @click="confirmTaskFileDeletion('signed contract')">
                        Delete Signed Contract
                    </LoadingButton>
                </div>
            </div>
            <div>
                <div class="task-page-documents__title">
                    <CIcon name="cilCrown"/>
                    Sent Contract
                </div>
                <div class="task-page-documents__area task-page-documents__area_blue">
                    <span class="task-page-documents__link"
                          @click="toggleDocumentPreview('tasks', task.id, isPendingInternalSignature ? 'preview-uploaded-file' : 'preview-file')">
                        {{ task.file_name }}
                    </span>
                </div>
                <div class="task-page-documents__buttons">
                    <CButton v-if="isTaskSubContractor && !isFullyExecuted" :disabled="loading" color="primary"
                             variant="outline" @click="downloadTaskFile">
                        <CSpinner v-if="loading" size="sm" color="primary"/>
                        <span>Download</span>
                    </CButton>
                    <LoadingButton v-if="canSendReminder" :disabled="loadingReminder || disableReminder"
                                   :loading="loadingReminder" color="primary" variant="outline" @click="reminderTask">
                        Remind Signer
                    </LoadingButton>
                    <CButton v-if="isTaskGeneralContractor && isPendingExternalSignature" color="primary"
                             variant="outline" @click="toggleNewContractVersion">
                        Upload new contract version
                    </CButton>
                    <CButton v-if="isTaskGeneralContractor && isPendingSignature" color="primary" variant="outline"
                             @click="uploadSignedContract">
                        Upload signed contract
                    </CButton>
                    <CButton v-if="isReadyForExternalSignature || isReadyForInternalSignature" color="primary"
                             @click="toggleShowTaskESignatureModal">
                        Sign the contract
                    </CButton>
                </div>
                <TaskPageDocumentFileArea v-if="newContractVersion" title="New Contract Version" color="blue"
                                          :upload="true" :loading="loading" @submit="updateTaskFile"/>
            </div>
        </section>
        <section v-if="isAcknowledge">
            <div>
                <div class="task-page-documents__area task-page-documents__area_green">
                    <span title="Document" class="task-page-documents__link"
                          @click="toggleDocumentPreview('tasks', task.id)">
                        {{ task.file_name }}
                    </span>
                </div>

                <div class="task-page-documents__buttons">
                    <LoadingButton color="primary" variant="outline" :loading="loading" @click="downloadTaskFile">
                        Download
                    </LoadingButton>
                    <LoadingButton v-if="showAcknowledgeButton" color="primary" :loading="loading"
                                   @click="handleAcknowledge">
                        Acknowledge
                    </LoadingButton>
                </div>
            </div>
        </section>
        <section v-if="isRequest">
            <TaskPageDocumentFileArea v-if="isTaskSubContractor && isPendingSubmission" title="Document" color="blue"
                                      :upload="true" :loading="loading" @submit="uploadTaskDocument"/>

            <TaskPageDocumentFileArea v-if="isPendingApproval || isCompleted" title="Document" color="green"
                                      icon="check-green" :document-name="task.file_name"
                                      :can-delete="isTaskSubContractor && isPendingApproval"
                                      @linkClicked="toggleDocumentPreview('tasks', task.id, 'preview-uploaded-file')"
                                      @delete="confirmTaskFileDeletion('uploaded document')">
                <LoadingButton color="primary" variant="outline" :loading="loading" @click="downloadTaskUploadedFile">
                    Download
                </LoadingButton>
                <CButton v-if="isTaskGeneralContractor && isPendingApproval" class="d-block" color="primary"
                         @click="$emit('reject')">
                    Reject & Send Message
                </CButton>
                <LoadingButton v-if="isCompleted && isTaskGeneralContractor" :loading="loading" color="danger"
                               variant="outline" @click="confirmTaskFileDeletion('uploaded document')">
                    Delete Uploaded Document
                </LoadingButton>
            </TaskPageDocumentFileArea>

            <TaskPageDocumentFileArea title="Sample" color="blue" icon="sample"
                                      :document-name="task.file_name"
                                      @linkClicked="toggleDocumentPreview('tasks', task.id)"/>
        </section>
    </div>
    <ConfirmDialog ref="confirmDialog"/>
    <DocumentPreviewer :visible="documentPreviewer.visible" :type="documentPreviewer.type"
                       :route="documentPreviewer.route" :type-id="documentPreviewer.typeId"
                       @closed="toggleDocumentPreview(null, null)"/>
    <TaskESignatureModal :visible="showTaskESignatureModal" :task="task" @close="toggleShowTaskESignatureModal"
                         @sign="toggleShowTaskESignatureModal(); updateEvent();"/>
</template>

<script>
import TaskESignatureModal from "@/components/TaskPages/TaskESignatureModal.vue";
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import TaskPageDocumentFileArea from '@/components/TaskPages/TaskPageDocumentFileArea.vue';
import DocumentPreviewer from '@/components/Documents/DocumentPreviewer.vue';
import GreenCheckIcon from '@/components/Icons/GreenCheckIcon.vue';
import Tasks from "@/api/v2/endpoints/Tasks";
import documentDownload from "@/mixin/documentDownload";
import LoadingButton from "@/components/LoadingButton.vue";
import {PENDING_ACKNOWLEDGEMENT} from "@/domain/Entities/Task/taskStatuses";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import taskHelper from "@/mixin/taskHelper";

export default {
    name: 'TaskPageDocument',
    components: {
        LoadingButton,
        TaskPageDocumentFileArea,
        ConfirmDialog,
        DocumentPreviewer,
        TaskESignatureModal,
        GreenCheckIcon
    },
    inject: ['toast'],
    mixins: [documentDownload, apiErrorHandler, taskHelper],
    props: {
        task: {
            type: Object,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            extension: 'pdf',
            loading: false,
            loadingReminder: false,
            disableReminder: false,
            newContractVersion: false,
            showTaskESignatureModal: false,
            documentPreviewer: {
                type: null,
                typeId: null,
                route: null,
                visible: false,
            }
        };
    },
    computed: {
        isReadyForExternalSignature() {
            return this.isPendingExternalSignature && this.isTaskExternalSigner;
        },
        isReadyForInternalSignature() {
            return this.isPendingInternalSignature && this.isTaskInternalSigner;
        },
        showAcknowledgeButton() {
            return this.isTaskSubContractor && this.task.status === PENDING_ACKNOWLEDGEMENT;
        },
        canSendReminder() {
            return this.isContract && (
                (this.isPendingExternalSignature && this.isTaskExternalAssignee && !this.isTaskExternalSigner)
                || (this.isPendingInternalSignature && this.isTaskInternalAssignee && !this.isTaskInternalSigner)
            );
        }
    },
    methods: {
        async confirmTaskFileDeletion(documentName) {
            await this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete the ${documentName}?`,
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then(confirm => {
                    if (confirm) this.deleteTaskFile()
                });
        },
        deleteTaskFile() {
            this.toggleLoading();

            Tasks
                .deleteUploadedFile(this.task.id)
                .then(() => {
                    this.toast('success', 'Task document successfully deleted!');
                    this.updateEvent();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        updateTaskFile(file) {
            this.toggleLoading();

            Tasks
                .file(this.task.id, this.defineUploadFileData(file))
                .then(() => {
                    this.toast('success', 'Task document has been successfully updated!');
                    this.toggleNewContractVersion();
                    this.updateEvent();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        uploadTaskDocument(file) {
            this.toggleLoading();

            Tasks
                .upload(this.task.id, this.defineUploadFileData(file))
                .then(() => {
                    this.toast('success', 'Document successfully sent to review!');
                    this.updateEvent();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        defineUploadFileData(file) {
            const data = new FormData();
            data.append('file', file);

            return data;
        },
        uploadSignedContract() {
            this.$router.push({name: 'ProjectScopeUpload', params: {scopeId: this.task.scope_id}});
        },
        async handleAcknowledge() {
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to acknowledge the task? This action can not be undone.',
                    onlyConfirmButton: true,
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then(confirm => {
                    if (confirm) this.acknowledgeTask();
                });
        },
        acknowledgeTask() {
            this.toggleLoading();

            Tasks
                .acknowledge(this.task.id)
                .then(() => {
                    this.toast('success', 'Document acknowledged successfully!');
                    this.updateEvent();
                })
                .finally(() => this.toggleLoading());
        },
        downloadTaskUploadedFile() {
            this.downloadFile(
                Tasks.downloadUploadedFile(this.task.id),
                `task-${this.task.id}-uploaded-file`,
                this.task.latest_upload.name
            )
        },
        downloadTaskFile() {
            this.downloadFile(Tasks.downloadFile(this.task.id), `task-${this.task.id}-file`, this.task.file_name)
        },
        downloadFile(promise, key, name) {
            this.toggleLoading();
            this.handleDownloadPromise(promise, key, name, () => this.toggleLoading());
        },
        reminderTask() {
            this.toggleLoadingReminder();

            Tasks
                .reminder(this.task.id)
                .then(() => this.toast('success', 'Reminder sent successfully!'))
                .finally(() => this.toggleLoadingReminder());
        },
        toggleDocumentPreview(type, typeId, route = 'preview-file') {
            this.documentPreviewer.type = type;
            this.documentPreviewer.typeId = typeId;
            this.documentPreviewer.route = route;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
        toggleNewContractVersion() {
            this.newContractVersion = !this.newContractVersion;
        },
        toggleShowTaskESignatureModal() {
            this.showTaskESignatureModal = !this.showTaskESignatureModal;
        },
        toggleLoadingReminder() {
            this.loadingReminder = !this.loadingReminder;
            if (this.loadingReminder) this.disableReminder = true;
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
        updateEvent() {
            this.$emit('update');
        }
    },
};
</script>

<style lang="scss">
.task-page-documents {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__modal {
        width: 688px;
    }

    &__buttons {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        & button {
            min-width: 110px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #1c262f;
        margin-bottom: 12px;

        & svg {
            color: #0068ad;
            min-width: 20px;
        }
    }

    &__area {
        display: flex;
        align-items: center;
        padding: 0 16px;
        min-height: 56px;
        border-radius: 8px;

        &_green {
            border: 1px dashed #008a6e;
            background: rgba(159, 233, 218, 0.24);
        }

        &_blue {
            border: 1px dashed #00437a;
            background: rgba(171, 231, 250, 0.25);
        }
    }

    &__link {
        color: #0068ad;
        cursor: pointer;
        font-size: 16px;
        text-decoration: underline;
    }
}
</style>
