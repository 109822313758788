<template>
    <CModal scrollable fullscreen :keyboard="false" :visible="visible" @close="closeEvent">
        <CModalHeader>
            <CModalTitle>Sign document: {{ task.file_name }}</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body">
            <div class="webviewer-container">
                <div class="webviewer" ref="viewer"></div>
            </div>
            <TaskESignatureIdentityConfirmationModal :visible="confirmationModal" :loading="submitting" :task="task"
                                                     :code-error="validationErrors.identity_code" @submit="submit"
                                                     @close="toggleConfirmationModal"/>
        </CModalBody>
        <CModalFooter v-if="readyToSign" class="justify-content-center p-0 pb-2 position-relative">
            <LoadingButton :loading="submitting" :aria-disabled="disableSubmitButton" color="primary"
                           @click="toggleConfirmationModal">
                Complete Agreement
            </LoadingButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import Tasks from "@/api/v2/endpoints/Tasks";
import {
    BUYER_INITIALS,
    BUYER_SIGNATURE,
    SELLER_INITIALS,
    SIGNABLE_SHORTCODES
} from "@/domain/Entities/Shortcode/shortcodes";
import {mapGetters} from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import fileExtension from "@/mixin/fileExtension";
import {PENDING_INTERNAL_SIGNATURE} from "@/domain/Entities/Task/taskStatuses";
import TaskESignatureIdentityConfirmationModal
    from "@/components/TaskPages/TaskESignatureIdentityConfirmationModal.vue";
import WebViewer from "@pdftron/webviewer";
import config from "@/domain/config";
import {PDF} from "@/domain/Entities/Upload/extensions";

export default {
    name: 'TaskESignatureModal',
    components: {TaskESignatureIdentityConfirmationModal, LoadingButton},
    mixins: [apiErrorHandler, fileExtension],
    emits: ['close', 'sign'],
    props: {
        visible: Boolean,
        task: Object
    },
    data() {
        return {
            readyToSign: false,
            submitting: false,
            webviewerInstance: null,
            webviewerAnnotationFields: [],
            confirmationModal: false,
        };
    },

    computed: {
        ...mapGetters(['user']),
        isSubContractor() {
            return this.user.company.id === this.task.external_company.id;
        },
        isGeneralContractor() {
            return this.user.company.id !== this.task.external_company.id;
        },
        disableSubmitButton() {
            return this.webviewerAnnotationFields
                .filter(annotation => this.isAnnotationFieldValueEmpty(annotation))
                .length > 0;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.setUpDocumentPreview();
            }
        },
    },
    methods: {
        setUpDocumentPreview() {
            this.downloadFileRequest()
                .then(response => this.setUpWebviewer(response.data));
        },
        downloadFileRequest() {
            if (this.task.status === PENDING_INTERNAL_SIGNATURE) {
                return Tasks.downloadUploadedFile(this.task.id);
            }

            return Tasks.downloadFile(this.task.id);
        },
        getWebviewerInstance(blob) {
            return WebViewer.Iframe({
                initialDoc: URL.createObjectURL(blob),
                licenseKey: config.services.apryse.licenseKey,
                path: `${config.app.baseUrl}/webviewer`,
                filename: this.task.file_name,
                extension: PDF,
            }, this.$refs.viewer);
        },
        setUpWebviewer(blob) {
            this.getWebviewerInstance(blob)
                .then(instance => {
                    this.webviewerInstance = instance;
                    this.configWebviewerFeatures();

                    this.webviewerInstance.Core.annotationManager.setCurrentUser(this.user.full_name);

                    this.webviewerInstance.Core.documentViewer
                        .addEventListener('annotationsLoaded', () => this.defineWebviewerAnnotationFields());

                    this.webviewerInstance.Core.documentViewer
                        .getTool(this.webviewerInstance.Core.Tools.ToolNames.SIGNATURE)
                        .addEventListener('annotationAdded', annotation => this.setAnnotationFieldSigned(annotation));
                });
        },
        configWebviewerFeatures() {
            this.webviewerInstance.UI.setZoomLevel('100%');
            this.webviewerInstance.UI.enableFeatures([this.webviewerInstance.UI.Feature.Initials]);
            this.webviewerInstance.UI.disableFeatures([this.webviewerInstance.UI.Feature.ContentEdit]);
            this.webviewerInstance.UI.setToolMode(this.webviewerInstance.Core.Tools.ToolNames.EDIT);
            this.webviewerInstance.UI.disableElements([
                'default-ribbon-group',
                'default-top-header',
                'tools-header',
            ]);
        },
        defineWebviewerAnnotationFields() {
            const {Annotations, annotationManager} = this.webviewerInstance.Core;
            
            this.webviewerAnnotationFields = this
                .webviewerWidgetAnnotations()
                .map(widgetAnnotation => this.createInitialsFields(widgetAnnotation))
                .map(widgetAnnotation => this.disableWidgetAnnotation(widgetAnnotation))
                .filter(widgetAnnotation => !widgetAnnotation.fieldFlags.get(Annotations.WidgetFlags.READ_ONLY))
                .map(widgetAnnotation => this.setWidgetAnnotationIndicator(widgetAnnotation, true))
                .map(widgetAnnotation => this.setWidgetAnnotationEvents(widgetAnnotation));

            this.readyToSign = true;
        },
        webviewerWidgetAnnotations() {
            const {annotationManager, Annotations} = this.webviewerInstance.Core;

            return annotationManager
                .getAnnotationsList()
                .filter(annotation => annotation instanceof Annotations.WidgetAnnotation)
        },
        createInitialsFields(widgetAnnotation) {
            if (this.isInitialsField(widgetAnnotation.fieldName)) {
                this.webviewerInstance.Core.annotationManager
                    .getFormFieldCreationManager()
                    .setSignatureOption(widgetAnnotation, 'initialsSignature');
            }

            return widgetAnnotation;
        },
        isInitialsField(fieldName) {
            return fieldName.includes(BUYER_INITIALS.key) || fieldName.includes(SELLER_INITIALS.key);
        },
        disableWidgetAnnotation(widgetAnnotation) {
            if (this.shouldDisableWidgetAnnotation(widgetAnnotation)) {
                widgetAnnotation.fieldFlags.set(this.webviewerInstance.Core.Annotations.WidgetFlags.READ_ONLY)
            }

            return widgetAnnotation;
        },
        shouldDisableWidgetAnnotation(widgetAnnotation) {
            const isGeneralSignableField = widgetAnnotation.fieldName.includes(BUYER_INITIALS.key) || widgetAnnotation.fieldName.includes(BUYER_SIGNATURE.key);

            return (this.isSubContractor && isGeneralSignableField) || (this.isGeneralContractor && !isGeneralSignableField);
        },
        setWidgetAnnotationEvents(widgetAnnotation) {
            widgetAnnotation.addEventListener('commit', () => this.setAnnotationFieldValue(widgetAnnotation));
            widgetAnnotation.addEventListener('change', () => this.setAnnotationFieldValue(widgetAnnotation));

            return widgetAnnotation;
        },
        setWidgetAnnotationIndicator(widgetAnnotation, active = false, label = null) {
            widgetAnnotation.setFieldIndicator(active, this.defineWidgetAnnotationIndicatorLabel(widgetAnnotation, label));
            this.webviewerInstance.Core.annotationManager.trigger('annotationChanged', [[widgetAnnotation], 'modify', {}]);

            return widgetAnnotation;
        },
        defineWidgetAnnotationIndicatorLabel(annotation, label = null) {
            const dictionary = {
                TextFormField: 'Fill out',
                SignatureFormField: 'Sign',
                CheckBoxFormField: 'Check'
            };

            return `${label || dictionary[annotation.getField().getFieldType()] || 'Select'}: ${this.defineWidgetAnnotationName(annotation.fieldName)}`;
        },
        defineWidgetAnnotationName(currentFieldName) {
            const shortcode = SIGNABLE_SHORTCODES.find(item => currentFieldName.includes(item.key));

            return shortcode ? shortcode.label : currentFieldName;
        },
        setAnnotationFieldValue(changedWidgetAnnotation) {
            this.webviewerAnnotationFields.map(annotationField => {
                if (this.areTheSameAnnotationFields(annotationField, changedWidgetAnnotation)) {
                    annotationField.value = changedWidgetAnnotation.value;

                    this.webviewerWidgetAnnotations()
                        .filter(widgetAnnotation => widgetAnnotation.fieldName === annotationField.fieldName)
                        .forEach(widgetAnnotation => this.setWidgetAnnotationIndicator(widgetAnnotation, true, 'Done'));
                }

                return annotationField;
            });
        },
        setAnnotationFieldSigned(changedWidgetAnnotation) {
            this.webviewerWidgetAnnotations().forEach(widgetAnnotation => {
                if (widgetAnnotation.isSignedByAppearance && widgetAnnotation.isSignedByAppearance()) {
                    this.webviewerAnnotationFields.map(annotationField => {
                        if (widgetAnnotation === annotationField) {
                            annotationField.value = 'Signed';
                            this.setWidgetAnnotationIndicator(annotationField, true, 'Done');
                        }

                        return annotationField;
                    });
                }
            });
        },
        areTheSameAnnotationFields(annotationA, annotationB) {
            return annotationA.PageNumber === annotationB.PageNumber && annotationA.X === annotationB.X && annotationA.Y === annotationB.Y;
        },
        isAnnotationFieldValueEmpty(annotationField) {
            if (annotationField.getField().getFieldType() === 'RadioButtonFormField') {
                return this.webviewerAnnotationFields.filter(annotation => {
                    return annotation.fieldName === annotationField.fieldName && annotation.value !== 'Off'
                }).length === 0;
            }

            return annotationField.value === '' || annotationField.value === 'Off';
        },
        revertDisabledWidgetAnnotations() {
            this.webviewerWidgetAnnotations()
                .filter(annotation => this.shouldDisableWidgetAnnotation(annotation))
                .map(annotation => {
                    annotation.fieldFlags.set(this.webviewerInstance.Core.Annotations.WidgetFlags.READ_ONLY, false);

                    return annotation;
                });
        },
        removeWidgetAnnotationIndicators() {
            this.webviewerWidgetAnnotations()
                .forEach(widgetAnnotation => this.setWidgetAnnotationIndicator(widgetAnnotation));
        },
        prepareToSubmit() {
            if (this.isSubContractor) {
                this.revertDisabledWidgetAnnotations();
            }

            this.removeWidgetAnnotationIndicators();
        },
        async submit(identityCode, termsAccepted) {
            this.toggleSubmitting();
            this.clearValidationErrors();
            this.prepareToSubmit();

            Tasks
                .sign(this.task.id, await this.defineSubmitData(identityCode, termsAccepted))
                .then(() => {
                    this.toast('success', 'Document has been successfully signed!')
                    this.signEvent()
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleSubmitting());
        },
        async defineSubmitData(identityCode, termsAccepted) {
            const editorDocument = this.webviewerInstance.Core.documentViewer.getDocument();
            const xfdfString = await this.webviewerInstance.Core.annotationManager.exportAnnotations();
            const file = this.convertArrayBufferToPdfBlob(await editorDocument.getFileData({xfdfString}));

            const data = new FormData();

            data.append('file', file, editorDocument.getFilename());
            data.append('terms_accepted', termsAccepted);
            data.append('identity_code', identityCode);

            return data;
        },
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },
        toggleConfirmationModal() {
            this.confirmationModal = !this.confirmationModal;

            if (this.confirmationModal) this.clearValidationErrors();
        },
        closeEvent() {
            this.$emit('close');
        },
        signEvent() {
            this.$emit('sign');
        },
    }
};
</script>

<style lang="scss" scoped>
.content-body {
    height: 100vh;

    .webviewer-container {
        display: flex;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.05);

        .webviewer {
            flex: 1;
        }
    }
}
</style>
